.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.input-group-right-button {
}

.drag-drop-container {
    height: 250px;
    border: 1px solid var(--bs-border-color);
    border-radius: 5px;
}

.drag-drop-container.error {
    border-color: #ef4d56;
}

.drag-drop-container.success {
    border-color: #22c5ad;
}

.drag-drop-inner {
    height: 100%;
    border: 1px dashed var(--bs-border-color);
    border-radius: 5px;
}

.drag-drop-inner .image {
    font-size: 2.5rem;
}

.drag-drop-inner .title {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.35;
    margin-bottom: 5px;
    margin-top: 15px;
    padding: 0 15px;
    color: var(--bs-body-color);
    text-align: center;
}

.a-like-btn {
    all: initial;
    align-items: center;
    color: #1269cf;
    display: inline-flex;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    justify-content: center;
    line-height: 1;
    transition-duration: 0.3s;
    transition-property: background-color, color;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
}

.a-like-btn:hover {
    border-bottom: 1px solid #1269cf;
}

.form-select.error {
    border-color: #ef4d56;
}

.form-select.success {
    border-color: #22c5ad;
}

.multiple-select-dropdown-option-container {
    position: absolute;
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    width: 100%;
    z-index: 999;
    top: 40px;
    border-top-left-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important;
}

.multiple-select-dropdown-option-container .option-container {
    overflow-y: auto;
    max-height: 200px;
}

.multiple-select-dropdown-option-container .search-container {
    height: 46px;
    width: 100%;
}

.multiple-select-dropdown-option-container .options {
    padding: 0.375rem 1.5rem;
}

.multiple-select-dropdown-option-container .options:hover {
    background-color: var(--bs-tertiary-bg);
    color: var(--bs-body-color);
}

.file-viewer {
    border: 1px solid var(--bs-border-color);
    border-radius: 5px;
    width: fit-content;
}

.tag-input .tag-container {
    background-color: var(--bs-green);
    margin-top: 5px;
}

.tag-input input {
    font-size: 0.812rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-card-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid transparent;
    /* border-radius: var(--bs-border-radius); */
    /* -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out; */
    /* transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out; */
    flex: 1;
    min-width: 50px;
    height: 19.47px;
}

.tag-input input:focus-visible {
    border: var(--bs-border-width) solid transparent;
    outline: initial;
}

.file-input-container {
    border: 1px dashed var(--bs-border-color);
    height: 10em;
    width: 10em;
    border-radius: 10px;
}

.file-input-container:hover {
    cursor: pointer;
}

.file-input-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.file-input-container.error {
    border-color: #ef4d56;
}

.file-input-container.success {
    border-color: #22c5ad;
}

.image-viewer {
    height: 10em;
    width: 10em;
    border-radius: 10px;
    object-fit: cover;
}

.RichTextEditor__editor___1QqIU .RichTextEditor__paragraph___3NTf9 {
    margin: 0px !important;
}

.text-editor,
.text-editor-toolbar {
    background-color: var(--bs-card-bg) !important;
    border-color: var(--bs-border-color) !important;
}

.text-editor-button {
    /* background: var(--bs-card-bg);
  color: var(--bs-body-color); */
}

.public-DraftEditor-content {
    min-height: 300px;
    max-height: 550px;
}

.text-editor.error {
    border-color: #ef4d56 !important;
}

.text-editor.success {
    border-color: #22c5ad !important;
}

.text-transform-none {
    text-transform: none !important;
}

.add-more-btn {
    height: 40px;
    width: 40px;
}

.partner-fd-container {
    border: 1px solid #53c5d2;
    margin: 0px 2px 2px;
}

.partner-fd-container .header {
    background-color: #53c5d2;
    display: flex;
    padding: 10px;
    align-items: center;
}

.partner-fd-container .header img {
    object-fit: contain;
}

.partner-fd-container .header h5 {
    margin-bottom: 0px;
    margin-left: 10px;
    color: #ffffff;
}

.partner-fd-container .desc li {
    font-size: 11px;
}

/* .partner-fd-container table tr,
.partner-fd-container table th,
.partner-fd-container table td {
    border-style: none !important;
} */

.partner-fd-btn {
    background-color: #ed6b75;
    border-radius: 0px;
    margin-left: 2px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 15px;
}

.partner-fd-btn:hover {
    background-color: #ed6b75;
    color: #ffffff;
}

.pdf-footer-left h6 {
    margin-bottom: 0px;
    font-weight: 800;
    font-size: 14px;
}

body[data-sidebar-size='collapsed'] .sidebar-logo-large {
    display: none;
}

body[data-sidebar-size='default'] .sidebar-logo-small {
    display: none;
}

.announcement {
    grid-area: announcement;
}
.mutual-funds {
    grid-area: mutual-funds;
}
.unlisted-equity {
    grid-area: unlisted-equity;
}
.rss-feed {
    grid-area: rss-feed;
}
.bonds {
    grid-area: bonds;
}
.top-funds {
    grid-area: top-funds;
}
.subscription {
    grid-area: subscription;
}
.blogs {
    grid-area: blogs;
}
.videos {
    grid-area: videos;
}

.grid-container {
    display: grid;
    grid-template-areas:
        'announcement announcement announcement mutual-funds unlisted-equity rss-feed rss-feed rss-feed'
        'announcement announcement announcement bonds top-funds rss-feed rss-feed rss-feed'
        'subscription subscription subscription subscription subscription rss-feed rss-feed rss-feed'
        'blogs blogs blogs blogs videos videos videos videos';
    gap: 10px;
}

.my-card {
    background-color: #202221;
}

.chat-box-right .chat-footer .chat-features button.a-like-btn {
    color: #c1cde0;
    font-size: 22px;
    margin-left: 12px;
}

.text-done {
    color: #1eb69f;
    text-transform: uppercase;
    font-size: 1.3rem;
}

.text-empty {
    color: #e12c34;
    text-transform: uppercase;
    font-size: 1.3rem;
}

.card.selected-card:hover {
    border: 1px solid #1eb69f;
    cursor: pointer;
}

.buisness-logo {
    grid-area: buisness-logo;
}

.buisness-info {
    grid-area: buisness-info;
}

.buisness-gst {
    grid-area: buisness-gst;
}

.buisness-domain-config {
    grid-area: buisness-domain-config;
}

.buisness-communication {
    grid-area: buisness-communication;
}

.buisness-grid-container {
    display: grid;
    grid-template-areas:
        'buisness-logo buisness-info'
        'buisness-gst buisness-info'
        'buisness-communication buisness-info'
        'buisness-communication buisness-domain-config';
    gap: 10px;
}

.accordian-card .card-header {
    padding: 1rem;
    cursor: pointer;
}

.color-input input {
    visibility: hidden;
    height: 0px;
    width: 0px;
    position: absolute;
}

.logo-admin img {
    height: 50px !important;
}