.custom-datatable {
    display: table;
    width: 100%;
}

.custom-tb-list {
    display: table-row;
}

.custome-tb-item {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding: 0.75rem 0.75rem;
}

.custom-tb-header .custome-tb-item {
    font-size: 0.9em;
    background-color: var(--bs-light);
}

.custom-tb-header .custome-tb-item:first-child {
    border-radius: 8px 0 0 8px;
}

.custom-tb-header .custome-tb-item:last-child {
    border-radius: 0 8px 8px 0;
}

.custom-tb-body:not(:last-child) .custome-tb-item {
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    border-color: var(--bs-border-color);
}

.table-secondary-text {
    color: var(--bs-label-color);
}

.pagination-control ul {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
