.browser-container {
    background-color: #1f2020;
    border-radius: 8px;
    overflow: hidden;
}

.browser-header {
    height: 40px;
    background-color: #3c3c3c;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.header-left {
    width: 50px;
    border-bottom-right-radius: 11px;
    background-color: #1f2020;
}

.header-right {
    flex: 1;
    border-bottom-left-radius: 11px;
    background-color: #1f2020;
}

.tab-container {
    width: 220px;
    background-color: #1f2020;
    display: flex;
}

.tab-container .tab {
    background-color: #3c3c3c;
    flex: 1;
    margin-top: 6px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 8px;
}

.tab img {
    height: 20px;
    width: 20px;
}

.tab h6 {
    color: #c7c7c7;
}

.browser-body {
    background-color: #3c3c3c;
    border-radius: 10px;
}

.searchbar-container {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    gap: 10px;
}

.active-font {
    color: #a5a5a5;
}

.deactive-font {
    color: #6c6c6c;
}

.search-box {
    background-color: #1f2020;
    flex: 1;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 4px;
}

.searchbar-container img {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    object-fit: cover;
}

.search-box .circle {
    height: 23px;
    width: 23px;
    border-radius: 12px;
    background-color: #3c3c3c;
    display: flex;
    align-items: center;
    justify-content: center;
}

.browser-body .body {
    overflow-y: scroll;
    height: 72vh;
}

.banner-section {
    background-image: url('https://start-nd.s3.ap-south-1.amazonaws.com/homestay/67176474ca0ae03b77abe906/website_banner/7a7eba01-1111-4002-bca8-7d41f20284d2.jpeg');
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    padding: 55px 0px 0px 30px;
}

.banner-section::after {
    content: '';
    background: linear-gradient(
        135deg,
        rgba(147, 0, 17, 0.5) 0,
        rgba(86, 20, 147, 0.5) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.banner-section .content {
    position: relative;
    z-index: 2;
}

.content h3 {
    color: rgb(250, 250, 250);
    font-weight: 700;
    font-family: Raleway, sans-serif;
    font-size: 1.5rem;
}

.banner-section .keyword {
    display: flex;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 1rem;
    align-items: center;
    margin-top: 15px;
}

.room-section,
.gallery-section {
    background-color: #ffffff;
    color: #616060;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-section {
    background-color: #29e9ce;
}

.room-section h3,
.room-section h6,
.gallery-section h3,
.gallery-section h6 {
    color: #616060;
    font-size: 1.4em;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.5px;
}

.gallery-section h3,
.gallery-section h6,
.gallery-section p {
    color: #000000 !important;
}

.room-section img,
.gallery-section img {
    object-fit: none;
    height: 16px;
}

.room-section p,
.gallery-section p {
    font-family: 'Lato', sans-serif;
    color: #747474;
    font-size: 0.9em;
    line-height: 1.6;
    text-align: center;
    font-weight: 500;
}

.room-section .card,
.gallery-section .card {
    width: 12rem;
}

.card img {
    height: 145px;
    object-fit: cover;
}

.card h6 {
    font-size: 0.7rem;
    font-weight: 600;
}

.room-section button,
.gallery-section button {
    background-color: #deb666;
    font-size: 10px;
    border: none;
    color: #ffffff;
    height: 22px;
    text-transform: uppercase;
    font-weight: 700;
}

.card .footer {
    background-color: #fafafa;
    border: 1px solid #f0f2f6;
}

.footer-section {
    background-color: #d2d2d2;
    display: flex;
    gap: 50px;
    padding: 20px 28px;
}

.footer-section .left,
.footer-section .right {
    flex: 1;
}

.footer-section .right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.left img {
    height: 28px;
}

.left p {
    font-size: 0.8em;
    line-height: 1.6;
    margin: 10px 0;
    color: #858a99;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.right h1 {
    color: #606060;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 1.1em;
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;
}

.right i {
    margin-right: 12px;
    font-size: 1em;
    color: #acbac4;
}

.right h5 {
    color: #777777;
    font-size: 0.8em;
}